<template>
  <div class="login">
    <div class="login-box">
      <div class="white-l-box text-center">
        <img src="/assets/img/logo-big.png" alt="" />
        <h2 class="bold-19 text-gray mt-2 mb-4">Prodyflow</h2>
        <h2 class="bold-24 text-black mt-3">{{ $t("setNewPassword.forgottenPassword") }}</h2>
        <div ref="captcha" class="mt-3" v-show="needCaptcha"></div>
        <div class="error my-2" v-if="error && !show">{{ error }}</div>
        <form v-on:submit.prevent="modifyPassword()" v-if="show">
          <div class="row text-start custom-input whitebg-input mt-4">
            <div class="col-12">
              <div class="mb-4">
                <label class="form-label bold-12 text-gray">{{ $t("register.email") }}</label>
                <input
                  type="email"
                  class="form-control remove-padding"
                  :placeholder="$t(`register.emailAddress`)"
                  disabled
                  v-model="email"
                />
              </div>
              <div class="mb-4">
                <label class="form-label bold-12 text-gray">{{ $t("register.password") }}</label>
                <Password
                  class="form-control remove-padding"
                  :placeholder="$t(`register.password`)"
                  v-model="password"
                />
              </div>
              <div class="error my-2 text-danger fw-bold" v-if="error">
                {{ error }}
              </div>
              <button type="submit" class="btn btn-type-2 w-100 text-center mt-2 pt-3 pb-3 mb-4">
                <span class="bold-14">{{ $t("setNewPassword.setNewPassword") }}</span>
              </button>
            </div>
          </div>
        </form>
        <h5 class="regular-14 text-gray">
          {{ $t("setNewPassword.knowYourPassword") }}
          <router-link to="/login" class="semibold-14 text-gradient pointer"
            >{{ $t("setNewPassword.logIn") }}</router-link
          >
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import Password from "@/components/inputs/Password";

export default {
  name: "SetNewPassword",
  components: { Password },
  data() {
    return {
      email: "",
      password: "",
      error: null,
      needCaptcha: false,
      show: false,
      token: null,
      hash: null,
      captchaID: null,
    };
  },
  mounted() {
    const route = useRoute();
    this.hash = route.params.hash;
    this.checkhash();
  },
  methods: {
    checkhash() {
      let data = new FormData();
      data.append("token", this.hash);
      if (this.needCaptcha) {
        // eslint-disable-next-line no-undef
        data.append("h-captcha-response", hcaptcha.getResponse(this.captchaID));
      }
      fetch(process.env.VUE_APP_API+"/auth/check-email-hash", {
        method: "POST",
        body: data,
        headers: { Accept: "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          this.needCaptcha = false;
          this.token = data.token;
          this.show = true;
          this.email = data.user.email;
        })
        .catch((err) => {
          err.json.then((data) => {
            if (data.needCaptcha) {
              this.captcha();
            } else {
              this.needCaptcha = false;
              this.error = data.message;
            }
          });
        });
    },
    captcha() {
      if (this.needCaptcha) {
        // eslint-disable-next-line no-undef
        hcaptcha.reset(this.captchaID);
      } else {
        this.needCaptcha = true;
        const _this = this;
        // eslint-disable-next-line no-undef
        this.captchaID = hcaptcha.render(this.$refs.captcha, {
          sitekey: "40502656-4482-4a91-9999-797738a6dd8d",
          callback: () => {_this.checkhash();}
        });
      }
    },
    modifyPassword() {
      let data = new FormData();
      data.append("password", this.password);

      fetch(process.env.VUE_APP_API+"/auth/modify-password", {
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          console.log(data);
          alert("Successful registration");
          this.$router.push("/login");
        })
        .catch((err) => {
          err.json.then((data) => {
            console.log(data);
            this.error = data.message;
          });
        });

      return false;
    },
  },
};
</script>
